export function typedAction<T extends string>(type: T): { type: T };
export function typedAction<T extends string, P extends any>(
    type: T,
    payload: P
): { type: T; payload: P };
export function typedAction(type: string, payload?: any) {
    return {type, payload};
}

export interface VideoData {
    id: string;
    title: string;
    thumbnailUrl: string;
    duration: string;
    definition: string;
    viewCount: string;
    likeCount: string;
    dislikeCount: string;
}
